import React from "react"
import Section from "wildComponents/Section"
import { Heading, VStack, useTheme } from "@chakra-ui/react"

function PerksText({ data }) {
  const { variables } = useTheme()
  const { containerPaddingY, componentSpacing } = variables

  return (
    <Section
      bg="dark.default"
      containerSize="lg"
      containerStyles={{ py: 0, pt: containerPaddingY }}
    >
      <VStack spacing={componentSpacing} align="flex-start">
        <Heading
          className="jumbo"
          color="white"
          dangerouslySetInnerHTML={{
            __html: data?.title
              ? data?.title
              : `NOT JUST ANOTHER <span>DUBAI <span class="yellow">FREE ZONE</span></span>`,
          }}
        />

        <Heading as="h3" className="thin-h3" color="white">
          {data?.text
            ? data?.text
            : `Make your mark in Dubai, the start-up capital of the world, and bring
          your venture to the forefront of the global market. Be in the hotspot
          for entrepreneurship & next-level ideas where quality of life is
          second to none. Launch your company in DUQE and enjoy all the perks
          Dubai has to offer.`}
        </Heading>
      </VStack>
    </Section>
  )
}

export default PerksText
