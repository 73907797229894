import React, { useRef } from "react"
import SectionWrapperCustom from "./SectionWrapperCustom"
import ScrollDown from "../../../../assets/icons/ScrollDown"
import Content from "./Content"
import { Center } from "@chakra-ui/react"
import useHeroAnimation from "./useHeroAnimation"
import QIcon from "../../../../assets/icons/QIcon"

function Hero({ props }) {
  const imgContainer = useRef()
  const img2 = useRef()
  const overlay = useRef()
  const content = useRef()
  const animation = useRef(null)

  useHeroAnimation(animation, {
    imgContainer,
    overlay,
    content,
    img2,
  })

  return (
    <SectionWrapperCustom ref={{ imgContainer, overlay }} props={props}>
      <Center layerStyle="fillSpace">
        <Content ref={content} />
      </Center>
      <ScrollDown />
      <QIcon
        ref={img2}
        position="absolute"
        color="#e0db2a"
        outline
        opacity="0.6"
        width={["100vw", "100vw", "80vw", "40vw", "40vw", "42vw", "47vw"]}
        height="auto"
        top={["10%", "10%", "-25%", "-25%", "-25%", "-25%", "-25%"]}
        right={["-50%", "-50%", "-40%", "-5%", "-5%", "-5%", "-5%"]}
        zIndex="3"
      />
    </SectionWrapperCustom>
  )
}

export default Hero
