import React, { forwardRef } from "react"
import { graphql } from "gatsby"
import { Box, Flex, useTheme } from "@chakra-ui/react"
import { Image } from "wildComponents/Image"
import { useLocalFile } from "../../../../hooks/useLocalFile"

export const query = graphql`
  fragment HERO_IMAGE on WpPage {
    acf_homepage {
      hero {
        image {
          ...IMAGE_DATA
        }
      }
    }
  }
`

const SectionWrapperCustom = forwardRef(({ alt, children }, ref) => {
  const { imgContainer, overlay } = ref
  const { variables } = useTheme()
  const image1 = useLocalFile("Hero_1_vynheu")
  const image2 = useLocalFile("Hero_2_butkjg")
  const image3 = useLocalFile("Hero_3_bhgssx")
  const {
    containerPaddingY,
    sectionPaddingX,
    sidebarMenuWidth,
    mobileNavHeight,
  } = variables

  return (
    <Flex
      as="section"
      id="homehero"
      h="100vh"
      width="100vw"
      zIndex="2"
      position="relative"
      pt={mobileNavHeight}
      px={sectionPaddingX}
      justify="center"
      _before={{
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bg: "dark.default",
        zIndex: "-10",
      }}
    >
      <Box
        layerStyle="fillSpace"
        position="relative"
        w={["100%", "100%", "container.xl"]}
        maxW={"container.xl"}
        py={containerPaddingY}
        ml={[0, 0, sidebarMenuWidth]}
      >
        {children}
      </Box>

      <Box
        w="100%"
        h="100%"
        position="absolute"
        zIndex="-2"
        left="0"
        top="0"
        objectFit="cover"
      >
        <Box className="overlay" layerStyle="overlay" ref={overlay}     willChange="transform" />
        <Box layerStyle="fillSpace" position="relative" overflow="hidden">
          <Box
            layerStyle="fillSpace"
            position="relative"
            overflow="hidden"
            ref={imgContainer}
            willChange="transform"
          >
            <Image
              img={image3}
              bgImage
              alt={alt}
              data-slide-index="2"
              loading="lazy"
              visibility="hidden"
              willChange="transform"
            />
            <Image
              img={image2}
              bgImage
              alt={alt}
              data-slide-index="1"
              loading="lazy"
              visibility="hidden"
              willChange="transform"
            />
            <Image
              img={image1}
              bgImage
              alt={alt}
              data-slide-index="0"
              loading="eager"
              willChange="transform"
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
})

export default SectionWrapperCustom
