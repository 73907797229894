import { useEffect, useState } from "react"
import { gsap, ScrollTrigger, TextPlugin } from "wildGsap"

function useHeroAnimation(animation, ref) {
  const { imgContainer, content, img2 } = ref
  const [currentSlide, setCurrentSlide] = useState(0)
  const slideDuration = 5

  /**** SLIDE TIMER ****/
  useEffect(() => {
    const q = gsap.utils.selector(imgContainer.current)
    const images = q("[data-slide-index]")

    setTimeout(() => {
      if (currentSlide === images.length - 1) {
        setCurrentSlide(0)
      } else {
        setCurrentSlide(currentSlide + 1)
      }
    }, slideDuration * 1000)
  }, [currentSlide, setCurrentSlide, imgContainer])

  /**** SLIDE ANIMATION ****/
  useEffect(() => {
    if (!TextPlugin) return
    const q = gsap.utils.selector(imgContainer.current)
    const c = gsap.utils.selector(content.current)
    const images = q("[data-slide-index]")
    if (images.length === 0) return
    const currentImage = images.filter(
      img => img.dataset.slideIndex === currentSlide.toString()
    )[0]
    const otherImages = images.filter(
      img => img.dataset.slideIndex !== currentSlide.toString()
    )
    const words = ["Disruptors", "Innovators", "Hustlers"]
    const featuredWord = c("h1 > div")[0]
    const dimensions = featuredWord.getBoundingClientRect().height

    gsap.set(featuredWord, { height: `${dimensions}px` })
    gsap.set(otherImages, { autoAlpha: 0 })
    gsap.set(featuredWord, {
      display: "block",
      onComplete: () => {
        featuredWord.innerHTML = ""
      },
    })
    gsap.to(featuredWord, {
      text: { value: words[currentSlide] },
      delay: 0,
      duration: 0.4,
      ease: "none",
      repeat: 1,
      yoyo: true,
      repeatDelay: 4,
    })
    gsap.to(currentImage, {
      autoAlpha: 1,
      delay: 0.2,
      ease: "Power3.out",
      onComplete: () => {
        gsap.set(images, { scale: 1 })
      },
    })
    gsap.to(currentImage, {
      scale: 1.1,
      duration: 5,
      ease: "none",
      force3D: true,
    })

    return () => {
      gsap.killTweensOf(featuredWord)
      gsap.killTweensOf(images)
      gsap.killTweensOf(currentImage)
    }
  }, [currentSlide, content, imgContainer])

  /**** ANIMATIONS NOT IN SLIDE ANIMATION ****/
  useEffect(() => {
    gsap.to(img2.current, {
      scale: 1.1,
      duration: 8,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      force3D: true,
    })
  }, [img2])

  useEffect(() => {
    if (!ScrollTrigger) return

    gsap.from(imgContainer.current, {
      opacity: 0,
      delay: 0.2,
      duration: 0.35,
      ease: "Power3.in",
    })

    const tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: imgContainer.current,
        toggleActions: "play none reverse none",
        start: "top top",
        end: "bottom top",
        scrub: 0.5,
      },
    })
    tl.to(imgContainer.current, {
      scale: 1.4,
      ease: "sine",
      force3D: true,
    })
    tl.to(
      img2.current,
      {
        rotate: -10,
        x: 100,
        y: -60,
      },
      0
    )
    return () => {
      tl.kill()
    }
  }, [img2, imgContainer])
}

export default useHeroAnimation
