import React, { useEffect, useRef } from "react"
import { gsap, ScrollTrigger } from "wildGsap"
import { Link } from "gatsby"
import Section from "wildComponents/Section"
import { Box, Button, Center } from "@chakra-ui/react"

const Perks = ({
  link = "/contact-us",
  linkText = "Start Your Business",
  containerStyles = {},
}) => {
  const svgRef = useRef()

  useEffect(() => {
    if (!ScrollTrigger) return

    const q = gsap.utils.selector(svgRef.current)
    gsap.fromTo(
      q(".perks-text"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "power3.out",
        stagger: 0.1,
        scrollTrigger: {
          trigger: svgRef.current,
          start: "top center",
        },
      }
    )
  }, [])

  return (
    <Section
      containerStyles={containerStyles}
      bg="dark.default"
      px={[0, 0]}
      overflow="hidden"
      _before={{
        content: `""`,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          "radial-gradient(circle, rgba(11,11,11,0) 0%, rgba(11,11,11,0) 70%,rgba(11,11,11,1) 100%, )",
        zIndex: 2,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <Box
        as="svg"
        ref={svgRef}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x={0}
        y={0}
        viewBox="0 0 618.4 457.5"
        xmlSpace="preserve"
        data-speed="0.8"
        sx={{ "#Radial_Gradient": { mixBlendMode: "overlay" } }}
        transformOrigin="50% 50%"
        transform={[
          "scale(1)",
          "scale(1)",
          "scale(1.1)",
          "scale(1.1)",
          "scale(1.1)",
          "scale(1.1)",
          "scale(1.1)",
          "scale(1.2)",
        ]}
      >
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".st2{stroke:#eddb00;stroke-miterlimit:10}.st2,.st3{fill:none}.st4{fill:#fff}.st5{font-family:'HelveticaNowDisplay-Bd'}.st6{font-size:10px}.st7{font-family:'HelveticaNowText-Regular'}",
          }}
        />
        <image
          width={2721}
          height={2721}
          id="BgImage"
          xlinkHref="https://res.cloudinary.com/wild-creative/image/upload/q_auto:eco/v1653951676/DUQE/BC94A57A5E15A7F7_porffo.webp"
          transform="matrix(.2279 0 0 .2316 -1.728 -173.191)"
          overflow="visible"
          loading="lazy"
        />
        <radialGradient
          id="Radial_Gradient_00000128484110468747964070000011916077132090472604_"
          cx="-128.706"
          cy="445.674"
          r="1.261"
          gradientTransform="matrix(0 -427.2952 -274.6911 0 122731.11 -54765.469)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#0b0b0b" stopOpacity={0} />
          <stop offset={1} stopColor="#0b0b0b" />
        </radialGradient>
        <path
          id="Radial_Gradient"
          fill="url(#Radial_Gradient_00000128484110468747964070000011916077132090472604_)"
          d="M-1.7-205.4h620.2v964.7H-1.7z"
        />
        <linearGradient
          id="Bottom_Cap_00000117673645790560442960000016062826665658727862_"
          gradientUnits="userSpaceOnUse"
          x1="309.214"
          y1="458.231"
          x2="309.214"
          y2="418.962"
        >
          <stop offset=".258" stopColor="#0b0b0b" />
          <stop offset={1} stopColor="#0b0b0b" stopOpacity={0} />
        </linearGradient>
        <path
          id="Bottom_Cap"
          fill="url(#Bottom_Cap_00000117673645790560442960000016062826665658727862_)"
          d="M0 415.5h618.4v42H0z"
        />
        <path className="st2" d="m359.9 146 38.4-29.7h143.6" />
        <path className="st2" d="m381.6 187.6 34-12h135.2" />
        <path className="st2" d="m350 259 48.3 39.7h143.6" />
        <path className="st2" d="m376.7 225.3 38.9 14h135.2" />
        <path className="st2" d="m260.2 146-38.4-29.7H78.2" />
        <path className="st2" d="m238.5 187.6-33.9-12H69.8" />
        <path className="st2" d="m270.1 259-48.3 39.7H78.2" />
        <path className="st2" d="m243.3 225.3-38.7 14H69.8" />
        <path className="st3" d="M464.8 127h85.4v34.4h-85.4z" />
        <text transform="translate(464.846 134.146)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Full repatriation
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            of capital &amp; profits
          </tspan>
        </text>
        <path className="st3" d="M78.2 127h85.4v34.4H78.2z" />
        <text transform="translate(78.212 134.146)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Full ownership
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            of the company
          </tspan>
        </text>
        <path className="st3" d="M456.6 189.2h101.8v34.4H456.6z" />
        <text transform="translate(456.632 196.34)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Access to thousands
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            of business activities
          </tspan>
        </text>
        <path className="st3" d="M70 189.2h101.8v34.4H70z" />
        <text transform="translate(70 196.34)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Zero tax on{" "}
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            personal income
          </tspan>
        </text>
        <path className="st3" d="M466.2 254.7H568v34.4H466.2z" />
        <text transform="translate(466.228 261.796)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Freedom to trade
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            internationally
          </tspan>
        </text>
        <path className="st3" d="M70 254.7h101.8v34.4H70z" />
        <text transform="translate(70 261.796)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            On-site team{" "}
          </tspan>
          <tspan x="64.6" y={0} className="st4 st7 st6">
            of{" "}
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            experts to assist you
          </tspan>
        </text>
        <path className="st3" d="M476.6 313.2h74.2v34.4h-74.2z" />
        <text transform="translate(476.642 320.365)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            End-to-end
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            support
          </tspan>
        </text>
        <path className="st3" d="M85.6 313.2h111.9v34.4H85.6z" />
        <text transform="translate(85.584 320.365)" className="perks-text">
          <tspan x={0} y={0} className="st4 st5 st6">
            Forward-thinking
          </tspan>
          <tspan x={0} y={12} className="st4 st7 st6">
            governance &amp; policies
          </tspan>
        </text>
        <linearGradient
          id="Top_Cap_00000052823497303187986790000001856041129836564413_"
          gradientUnits="userSpaceOnUse"
          x1="309.214"
          y1="46.944"
          x2="309.214"
          y2="3.801"
        >
          <stop offset={0} stopColor="#0b0b0b" stopOpacity={0} />
          <stop offset={1} stopColor="#0b0b0b" />
        </linearGradient>
        <path
          id="Top_Cap"
          fill="url(#Top_Cap_00000052823497303187986790000001856041129836564413_)"
          d="M0 0h618.4v46.1H0z"
        />
      </Box>

      <Center zIndex="2" bottom="0" height="30%" w="full">
        <Link to={link}>
          <Button>{linkText}</Button>
        </Link>
      </Center>
    </Section>
  )
}

export default Perks
