import { Box, Button, Heading, Stack, useTheme } from "@chakra-ui/react"
import React, { forwardRef } from "react"
import { Link } from "wildComponents/Link"

const Content = forwardRef((props, ref) => {
  const { variables } = useTheme()
  const { componentSpacing } = variables
  return (
    <Stack
      direction={["column", "column", "reverse"]}
      alignItems="flex-start"
      width="100%"
      spacing={componentSpacing}
      ref={ref}
    >
      <Heading as="h1" color="white" className="home-hero">
        Duqe is for the <Box color="brandYellow.default">disruptors</Box>
      </Heading>
      <Heading as="h3" color="white" className="thin-h3">
        Set up your company in Dubai at DUQE Free Zone, based on the prestigious
        QE2.
      </Heading>
      <Stack
        direction={["column", "column", "row"]}
        w="full"
        spacing={[5, 5, 6]}
      >
        <Link to="/contact-us/">
          <Button variant="secondary">Book free consultation</Button>
        </Link>
        <Link to="/cost-calculator/">
          <Button variant="outlineWhite">How much does it cost?</Button>
        </Link>
      </Stack>
    </Stack>
  )
})

export default Content
