import React, { Suspense } from "react"
import Section from "wildComponents/Section"
import { Heading, Stack, VStack } from "@chakra-ui/react"
const MasterForm = React.lazy(() =>
  import("../../../components/forms/master-form/Form")
)

function Contact() {
  const isSSR = typeof window === "undefined"

  return (
    <Section className="light" h={["unset", "unset", "100vh", "100vh"]}>
      <Stack direction={["column", "column", "row"]} spacing={[20, 20, 40]}>
        <VStack spacing={7} align="start" flex="1">
          <Heading className="jumbo">Contact Us now</Heading>
          <Heading as="h3" className="thin-h3">
            Make your mark in Dubai – the start-up capital of the world – and
            bring your venture to the forefront of the global market.
          </Heading>
        </VStack>
        {!isSSR && (
          <Suspense fallback={<div>Loading...</div>}>
            <MasterForm flex="1" />
          </Suspense>
        )}
      </Stack>
    </Section>
  )
}

export default Contact
