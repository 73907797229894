import Cookies from "js-cookie"
import React, { useEffect, useRef } from "react"
import { data as costCalcData } from "../../../../components/forms/cost-calculator/data.js"
import data from "../../../../components/forms/cost-calculator/data.json"
import { gsap, ScrollTrigger } from "wildGsap"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import {
  Button,
  Center,
  Heading,
  Image,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import CrossIcon from "../../../../assets/icons/CrossIcon"
import SectionWrapperCustom from "./SectionWrapperCustom"

function HowMuchDoesItCost({ crossColor = "dark.default", ...props }) {
  const cross = useRef()
  const img = useRef(null)
  const button = useRef()
  const content = useRef()
  const animation = useRef(null)
  const section = useRef()
  const addLag = useBreakpointValue([false, false, true])
  const cookieData = Cookies.get("cost-calculator")

  function handleSelect(e) {
    if (cookieData) return
    const updatedAnswers = [...costCalcData]
    updatedAnswers[0].value = e.target.value
    const storageData = updatedAnswers.map(answer => {
      return { name: answer.name, value: answer.value }
    })

    Cookies.set("cost-calculator", JSON.stringify(storageData), { expires: 7 })
  }

  useEffect(() => {
    ScrollTrigger.refresh()
    if (animation.current !== null) return
    const contentDimensions = content.current.getBoundingClientRect()
    const left = window.innerWidth / 2 - contentDimensions.width / 2
    const bottom = window.innerHeight / 2 - contentDimensions.height / 2

    gsap.to(cross.current, {
      rotation: -50,
      ease: "linear",
      scrollTrigger: {
        trigger: cross.current,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    })

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section.current,
        start: "bottom bottom",
        pin: true,
        end: "+=100%",
        scrub: true,
        onEnter: () => {
          gsap.set(section.current, { overflow: "hidden" })
        },
        onLeaveBack: () => {
          gsap.set(section.current, { overflow: "visible" })
        },
      },
    })
    tl.to(img.current, {
      scale: 5,
      ease: "linear",
      duration: 20,
    })
    tl.set(content.current, { left: left, bottom: bottom })
    tl.fromTo(
      content.current,
      {
        autoAlpha: 0,
        scale: 0,
      },
      {
        autoAlpha: 1,
        scale: 1,
        duration: 7,
        ease: "power2.in",
      }
    )
    tl.to(button.current, { autoAlpha: 0, delay: 10 })
    animation.current = tl
  }, [])

  return (
    <SectionWrapperCustom ref={section} {...props}>
      <VStack spacing={[8, 8, 12]}>
        <Heading className="jumbo" textAlign="center">
          How much does it cost?
        </Heading>
        <Heading as="h3" className="thin-h3" textAlign="center">
          Use the <strong>DUQE Cost Calculator</strong> to find out the cost of
          your business setup.
          <Text as="br" display={["none", "none", "block"]} /> It’s the number
          one question we get asked. <br />
          <strong>Find out in an instant.</strong>
        </Heading>
        <Link to="/cost-calculator/">
          <Button ref={button}>Calculate Cost</Button>
        </Link>
      </VStack>
      <VStack
        spacing={12}
        w="full"
        ref={content}
        visibility="hidden"
        zIndex="20"
        position="absolute"
      >
        <Heading color="white" textAlign={["center", "center", "left"]}>
          Calculate the cost of your free zone business setup.
        </Heading>
        <Stack direction={["column", "column", "row"]} w="full">
          <VStack align={["center", "center", "center"]} w="full">
            <Heading color="white" as="h6">
              {data[0].question}
            </Heading>
            <Stack
              direction={["column", "column", "row"]}
              p={4}
              spacing={4}
              justify="center"
              align="center"
            >
              <Select
                bg="white"
                placeholder="Please Select"
                size="md"
                name={costCalcData[0].name}
                id={costCalcData[0].name}
                w={["full", "full", "50%"]}
                onChange={e => {
                  handleSelect(e)
                }}
              >
                {costCalcData[0].options.map((option, index) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </Select>
              <Link to="/cost-calculator/">
                <Button w="200px" h="100%" rightIcon={<ArrowForwardIcon />}>
                  Continue
                </Button>
              </Link>
            </Stack>
          </VStack>
        </Stack>
      </VStack>
      <Center mt={[12, 12, 20]}>
        <Image
          h={["60vh", "60vh", "908px"]}
          w={["100%", "100%", "614px"]}
          src="https://res.cloudinary.com/wild-creative/image/upload/q_auto:eco/v1653639052/DUQE/Calculator_z7d2pl.webp"
          ref={img}
          transformOrigin="center 30%"
          zIndex="2"
          transform="translateY(1px)"
          loading="lazy"
          alt="DUQE Cost Calulator"
        />
      </Center>

      <CrossIcon
        position="absolute"
        width={["100px", "100px", "500px"]}
        height={["100px", "100px", "500px"]}
        top={["-50px", "-50px", "-250px"]}
        right={["-50px", "-50px", "-250px"]}
        zIndex="1"
        color={crossColor}
        data-speed={addLag ? "1.1" : 1}
        ref={cross}
      />
    </SectionWrapperCustom>
  )
}

export default HowMuchDoesItCost
